import dedent from 'dedent';
import axios from 'axios';

const slackWebhookURL =
  'https://hooks.slack.com/services/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';
axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded';

export const logEvent = ({ userData, type }) => {
  try {
    const url = slackWebhookURL;
    const payload = {
      text: dedent`
                  ---------------------------
                    lang: ${userData?.lang}
                    name: ${userData?.name}
                    mbti: ${userData?.mbti}
                    flower: ${userData?.flower}
                    suggest_plant: ${userData?.suggestPlant}
                    luck_type: ${userData?.luckType}
                    timezone: ${
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                    time: ${new Date()}
                    user_agent: ${window.navigator.userAgent}
                  `,
    };
    const params = new URLSearchParams();
    params.append('payload', JSON.stringify(payload));

    axios
      .post(url, params)
      .catch((err) => console.error('slack err', err.response));
  } catch (e) {
    console.log('send result error', e);
  }
};
