const colors = {
  pink1: '#FFF7F1',
  pink2: '#FFE5F0',
  red1: '#C95681',
  red2: '#EB5757',
  red3: '#C398A8',
  grey: '#828282',
  grey2: '#333333',
  grey3: '#FAFAFA',
  dark: '#333333',
  orange1: '#FFE5D0',
  orange2: '#F2994A',
  green1: '#2ED562',
};

const fonts = {
  h1: '700 56px/84px Poppins, sans-serif',
  h2: '700 32px/48px Poppins, sans-serif',
  h3: '700 28px/42px Poppins, sans-serif',
  h4: '500 28px/42px Poppins, sans-serif',
  h5: '700 24px/36px Poppins, sans-serif',
  b1: '700 16px/22px Poppins, sans-serif',
  b2: '600 13px/20px Poppins, sans-serif',
  b3: '500 13px/17px Poppins, sans-serif',
  b4: '700 12px/17px Poppins, sans-serif',
  b5: '600 16px/17px Poppins, sans-serif',
  b6: '500 14px/17px Poppins, sans-serif',
};

const theme = {
  default: {
    ...colors,
    fonts,
    backgroundColor: colors.pink1,
  },
};

export default theme;
