import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import bgImg from '../assets/bgclean.png';

const Layout = () => {
  return (
    <Container>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  background-color: ${(props) => props.theme.pink1};
  height: 100vh;
  display: flex;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 auto 0 auto;
  background-image: url(${bgImg});
  background-size: cover;
`;

export default Layout;
