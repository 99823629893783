import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import ko from './ko.json';
import zh_TW from './zh_TW.json';
import zh_CN from './zh_CN.json';
import pt from './pt.json';

const resources = {
  en: { translation: en },
  ko: { translation: ko },
  'zh-TW': { translation: zh_TW },
  'zh-CN': { translation: zh_CN },
  pt: { translation: pt },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
