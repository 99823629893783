import React, { useState } from 'react';
import styled from 'styled-components';

const Question = ({
  question,
  options,
  onAnswerSelected,
  styled,
  selectedAns = '',
}) => {
  const [activeIdx, setActiveIdx] = useState(false);
  const [hoverIdx, setHoveredIdx] = useState(10);
  const handleInputChange = (answer, ansIdx) => {
    onAnswerSelected(answer, ansIdx);
    setActiveIdx(ansIdx);
  };

  const handleMouseEnter = (hoverIdx) => {
    setHoveredIdx(hoverIdx);
  };

  const handleMouseLeave = (hoverIdx) => {
    setHoveredIdx(hoverIdx + 4);
  };

  return (
    <Wrapper styled={styled}>
      <QuestionTitle>{question}</QuestionTitle>
      <Options>
        {options.map((option, ansIdx) => (
          <Option
            key={option.text}
            $isActive={activeIdx === ansIdx || selectedAns === option.answer}
            $isHovered={hoverIdx === ansIdx}
            $selectedAns={selectedAns}
            onClick={() => handleInputChange(option.answer, ansIdx)}
            onMouseEnter={() => handleMouseEnter(ansIdx)}
            onMouseLeave={() => handleMouseLeave(ansIdx)}
          >
            {option.text}
          </Option>
        ))}
      </Options>
    </Wrapper>
  );
};

export default Question;

const Wrapper = styled.div``;

const QuestionTitle = styled.div`
  font: ${(props) => props.theme.fonts.h5};
  color: ${(props) => props.theme.red1};
  margin-bottom: 20%;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Option = styled.a`
  padding: 14px 24px;
  font: ${(props) => props.theme.fonts.b1};
  background-color: ${(props) =>
    props.$isActive
      ? props.theme.red1
      : props.$isHovered
      ? props.theme.red1
      : 'white'};
  color: ${(props) =>
    props.$isActive ? 'white' : props.$isHovered ? 'white' : props.theme.dark};
  border-radius: 16px;
  border: 1px solid
    ${(props) =>
      props.$isHovered
        ? props.theme.red1
        : props.$isActive
        ? props.theme.red1
        : props.theme.grey};
  cursor: pointer;
`;
