import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../App';
import luckQuestion from '../data/luckQuestion.json';
import Question from '../component/Question';
import flowerData from '../data/flower.json';

const LuckForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userData, handleUpdateUserData } = useContext(UserContext);
  const lang = userData?.lang || 'en';
  const question = luckQuestion[lang];

  const handleAnswerSelected = (ans, idx) => {
    const plant = getSuggestedPlant(userData?.mbti, idx);
    updateLuckTypeToContext({ luckType: ans, suggestPlant: plant });
    navigate('/result');
  };

  const getSuggestedPlant = (mbti, idx) => {
    const data = flowerData[mbti];
    return data?.['suggest_plant']?.[idx];
  };

  const updateLuckTypeToContext = ({ luckType, suggestPlant }) => {
    const _userData = {
      ...userData,
      luckType,
      suggestPlant,
    };
    handleUpdateUserData(_userData);
  };

  const checkValidationOfData = (data) => {
    return data !== '' && data !== undefined;
  };

  useEffect(() => {
    const isDataValid = checkValidationOfData(userData.mbti);

    if (isDataValid === false) {
      setTimeout(() => {
        navigate('/');
      }, 5);
    }
  }, [userData]);

  return (
    <Wrapper>
      <Subtitle>{t('luck_question_subtitle')}</Subtitle>
      <Question
        key={question.question}
        question={question.question}
        options={question.options}
        onAnswerSelected={(ans, idx) => handleAnswerSelected(ans, idx)}
      />
    </Wrapper>
  );
};

export default LuckForm;

const Wrapper = styled.div`
  margin: 20% 10%;
  padding-bottom: 20px;
`;

const Subtitle = styled.div`
  font: ${(props) => props.theme.fonts.b2};
  color: ${(props) => props.theme.red1};
  margin-bottom: 10px;
  font-size: 14px;
`;
