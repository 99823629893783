import React from 'react';
import styled from 'styled-components';

const PinkButton = ({ text, onClick, styled, ...rest }) => (
  <Button styled={styled} onClick={onClick} {...rest}>
    <Text>{text}</Text>
  </Button>
);

const Button = styled.a`
  background-color: ${(props) => props.theme.red1};
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 16px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 1px 0.7px 2.7px rgba(0, 0, 0, 0.022),
    2.5px 1.7px 6.9px rgba(0, 0, 0, 0.031),
    5.1px 3.5px 14.2px rgba(0, 0, 0, 0.039),
    10.6px 7.3px 29.2px rgba(0, 0, 0, 0.048), 29px 20px 80px rgba(0, 0, 0, 0.07);
`;

const Text = styled.div`
  font: ${(props) => props.theme.fonts.b5};
  color: white;
`;

export default PinkButton;
