import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { UserContext } from '../App';
import Question from '../component/Question';
import Conditional from '../component/Conditional';
import mbtiQuestion from '../data/mbtiQuestion.json';
import flowerWithMBTI from '../data/flower.json';
import styled from 'styled-components';
import theme from '../theme';
import PinkButton from '../component/PinkButton';

const MBTIForm = () => {
  const { t } = useTranslation();
  const mbtiOptions = [
    { label: t('type_ISTJ'), value: 'ISTJ' },
    { label: t('type_ISFJ'), value: 'ISFJ' },
    { label: t('type_INFJ'), value: 'INFJ' },
    { label: t('type_INTJ'), value: 'INTJ' },
    { label: t('type_ISTP'), value: 'ISTP' },
    { label: t('type_ISFP'), value: 'ISFP' },
    { label: t('type_INFP'), value: 'INFP' },
    { label: t('type_INTP'), value: 'INTP' },
    { label: t('type_ESTP'), value: 'ESTP' },
    { label: t('type_ESFP'), value: 'ESFP' },
    { label: t('type_ENFP'), value: 'ENFP' },
    { label: t('type_ENTP'), value: 'ENTP' },
    { label: t('type_ESTJ'), value: 'ESTJ' },
    { label: t('type_ESFJ'), value: 'ESFJ' },
    { label: t('type_ENFJ'), value: 'ENFJ' },
    { label: t('type_ENTJ'), value: 'ENTJ' },
  ];

  const navigate = useNavigate();
  const { userData, handleUpdateUserData } = useContext(UserContext);
  const lang = userData?.lang || 'en';
  const question = mbtiQuestion[lang];
  const [mbtiIsKnown, setMbtiIsKnown] = useState();
  const [questionOrder, setQuestionOrder] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [dropdownAns, setDropdownAns] = useState();

  const handleIfMbtiIsKnown = (answer) => {
    if (answer) {
      setMbtiIsKnown(true);
    } else setMbtiIsKnown(false);
  };

  //MBTI simple question
  const handleAnswerSelected = (idx, answer) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[idx] = answer;
    setSelectedAnswers(newAnswers.join(''));
    setQuestionOrder((prev) => prev + 1);

    if (newAnswers.length === 4) {
      UpdateMBTITypeToContext(newAnswers.join(''));
      navigate('/luck');
    }
  };

  //MBTI dropdown
  const handleDropdownChange = (selecedOption) => {
    setDropdownAns(selecedOption.value);
  };

  //MBTI dropdown
  const handleDropdownRedirect = () => {
    if (dropdownAns) {
      UpdateMBTITypeToContext(dropdownAns);
      navigate('/luck');
    }
  };

  const UpdateMBTITypeToContext = (mbti) => {
    const _userData = {
      ...userData,
      mbti,
      flower: flowerWithMBTI[mbti]?.flower,
    };
    handleUpdateUserData(_userData);
  };

  const checkValidationOfData = (data) => {
    return data !== '' && data !== undefined;
  };

  useEffect(() => {
    const isDataValid = checkValidationOfData(userData.name);

    if (isDataValid === false) {
      setTimeout(() => {
        navigate('/');
      }, 5);
    }
  }, []);

  return (
    <Wrapper>
      <Conditional if={mbtiIsKnown === undefined}>
        <Question
          question={t('mbti_first_question')}
          options={[
            { text: t('mbti_yes_button'), answer: true },
            { text: t('mbti_no_button'), answer: false },
          ]}
          onAnswerSelected={(answer) => handleIfMbtiIsKnown(answer)}
        />
      </Conditional>

      <Conditional if={mbtiIsKnown !== undefined && mbtiIsKnown}>
        <TitleWrapper>
          <Title>{t('mbti_second_desc')}</Title>
          <StyledLink to="/mbti" onClick={() => handleIfMbtiIsKnown(false)}>
            <div>{t('mbti_second_question_desc2')}</div>
            <div>{t('mbti_second_question_desc3')}</div>
          </StyledLink>
        </TitleWrapper>

        <Select
          options={mbtiOptions}
          value={dropdownAns?.label}
          onChange={handleDropdownChange}
          placeholder={t('mbti_second_question')}
          isSearchable={ false }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? theme.default.red1 : '#828282',
              borderRadius: 16,
              outline: 'none',
              boxShadow: 'none',
              padding: 5,
              font: theme.default.fonts.b2,
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              font: theme.default.fonts.b2,
            }),
            option: (state) => ({
              padding: '16px 24px',
              color: theme.default.dark,
              cursor: 'pointer',
              font: theme.default.fonts.b2,
              backgroundColor: state.isSelected
                ? theme.red1
                : state.isFocused
                ? theme.red2
                : 'transparent',
              ':active': {
                backgroundColor: state.isSelected
                  ? theme.red1
                  : state.isFocused
                  ? theme.red2
                  : 'transparent',
              },
              ':hover': {
                backgroundColor: theme.default.pink2,
              },
            }),
          }}
        />
        <ContinueButton
          text={t('mbti_continue_button')}
          $disabled={dropdownAns}
          onClick={() => handleDropdownRedirect()}
        />
      </Conditional>

      <Conditional if={mbtiIsKnown !== undefined && !mbtiIsKnown}>
        <IndexSection>
          <QuestionIndex>{questionOrder + 1}</QuestionIndex>
          <QuestionIndex2>/ 4</QuestionIndex2>
        </IndexSection>
        {question?.map((item, idx) => (
          <Conditional if={questionOrder === idx} key={item.question}>
            <Question
              selectedAns={selectedAnswers[idx]}
              question={item.question}
              options={item.options}
              onAnswerSelected={(answer) => handleAnswerSelected(idx, answer)}
            />

            <PageButtons>
              <PageButton onClick={() => setQuestionOrder((prev) => prev - 1)}>
                {questionOrder >= 1 ? t('mbti_prev_button') : ' '}
              </PageButton>
              <PageButton
                onClick={() => {
                  setQuestionOrder((prev) => prev + 1);
                }}
              >
                {questionOrder < 3 && selectedAnswers[idx] !== undefined
                  ? t('mbti_next_button')
                  : ' '}
              </PageButton>
            </PageButtons>
          </Conditional>
        ))}
      </Conditional>
    </Wrapper>
  );
};

export default MBTIForm;

const Wrapper = styled.div`
  margin: 20% 10%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px;
`;

const Title = styled.div`
  font: ${(props) => props.theme.fonts.h5};
  color: ${(props) => props.theme.red1};
`;

const StyledLink = styled(Link)`
  font: ${(props) => props.theme.fonts.b2};
  color: ${(props) => props.theme.red1};
`;

const ContinueButton = styled(PinkButton)`
  display: flex;
  justify-content: center;
  font: ${(props) => props.theme.fonts.b5};
  background-color: ${(props) =>
    props.$disabled ? props.theme.red1 : props.theme.red3};
  color: white;
  margin-top: 17px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
`;

const IndexSection = styled.div`
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => props.theme.dark};
  display: flex;
`;

const QuestionIndex = styled.div`
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => props.theme.red1};
  margin-bottom: 10px;
  margin-right: 5px;
`;

const QuestionIndex2 = styled(QuestionIndex)`
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => props.theme.dark};
`;

const PageButtons = styled.div`
  margin-top: 30px;
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => props.theme.grey};
  display: flex;
  justify-content: space-between;
`;

const PageButton = styled.div`
  cursor: pointer;
`;
