import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../App';
import { logEvent } from '../bi/slackApi';

import useDownloadAsImage from '../hooks/useDownloadAsImage';
import flowerData from '../data/flower.json';
import suggestPlantData from '../data/suggestPlant.json';
import luckTypeData from '../data/luckType.json';
import styled from 'styled-components';
import Conditional from '../component/Conditional';
import appIcon from '../assets/icon/app.png';
import qrCode from '../assets/icon/qrCode.png';
import bgImg from '../assets/resultbg.png';
import appImage from '../assets/icon/icon.png';
import phonewithinfo from '../assets/phonewithinfo.png';
import PinkButton from '../component/PinkButton';

const ResultPage = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataIsValid, setDataIsvalid] = useState(null);
  const [isTransitionPage, setIsTransitionPage] = useState(true);
  const [isTapTextShow, setTapTextShow] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const lang = userData?.lang || 'en';
  const mbti = userData?.mbti || undefined;
  const luckType = userData?.luckType;

  const mbtiData = flowerData?.[mbti];
  const flowerName = mbtiData?.[lang]?.flower;
  const flowerDesc = mbtiData?.[lang]?.desc;
  const flowerImg = mbtiData?.img;
  const suggestPlant = suggestPlantData?.[userData?.suggestPlant]?.[lang];
  const _suggestPlantDesc =
    luckTypeData?.[luckType]?.[lang]?.[mbtiData.order] ||
    luckTypeData?.[luckType]?.[lang]?.[0];
  const suggestPlantDesc = _suggestPlantDesc?.replace(
    '"x plant"',
    suggestPlant
  );
  const suggestPlantImg = suggestPlantData?.[userData?.suggestPlant]?.img;
  const ref = useRef(null);
  const { handleDonwloadImage } = useDownloadAsImage(ref, {
    parent: true,
    backgroundColor: '#C95681',
    mbti,
  });

  const checkValidationOfData = (data) => {
    return data?.trim() !== '' && data !== undefined;
  };

  const getResult = () => {
    if (isTapTextShow) {
      setIsTransitionPage(false);
      logEvent({ userData });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText('https://plantcam.pse.is/sharequiz')
      .then(() => {
        setPopupVisible(true);
        setTimeout(() => {
          setPopupVisible(false);
        }, 500);
      })
      .catch((error) => {
        console.error(`Error copying link: ${error}`);
      });
  };

  useEffect(() => {
    const isDataValid = checkValidationOfData(mbti);
    setDataIsvalid(isDataValid);

    if (isDataValid === false) {
      setTimeout(() => {
        navigate('/');
      }, 5);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTapTextShow(true);
    }, 1500);
  }, []);

  return (
    <>
      <Conditional if={isTransitionPage && dataIsValid}>
        <TransitionWrapper>
          <CaptionWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <PhoneImage src={phonewithinfo} />
              <Caption>{t('transition_caption_1')}</Caption>
              <Link
                href="https://onelink.to/a8exdn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppImage src={appImage} />
              </Link>
            </div>
            <Caption>{t('transition_caption_2')}</Caption>
          </CaptionWrapper>
          <StyledPinkButton
            onClick={getResult}
            $disabled={isTapTextShow}
            text={
              isTapTextShow
                ? t('transition_result_text')
                : t('transition_loading_text')
            }
          />
        </TransitionWrapper>
      </Conditional>

      <Conditional if={!isTransitionPage && dataIsValid}>
        <Wrapper ref={ref}>
          <TopWrapper>
            <NameWrapper>
              <div>👋</div>
              <Name>{userData?.name}</Name>
              <StyledText>{t('result_text_is')}</StyledText>
            </NameWrapper>
            <PlantDetailWrapper>
              <Content
                type="flower"
                plant={`${mbti} - ${flowerName}`}
                desc={flowerDesc}
                imgSrc={`flower${flowerImg}.png`}
                mbti={mbti}
              />
              <Content
                type="plant"
                plant={suggestPlant}
                desc={suggestPlantDesc}
                imgSrc={`flower${suggestPlantImg}.png`}
              />
            </PlantDetailWrapper>
          </TopWrapper>

          {popupVisible && <Popup message={t('result_copy_text')} />}

          <AppCard>
            <AppcardWrapper>
              <Link
                href="https://onelink.to/a8exdn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <Icon src={appIcon} />
                  <AppNameWrapper>
                    <AppName>PlantCam App</AppName>
                    <AppTitle>Top-rated plant identifier</AppTitle>
                  </AppNameWrapper>
                </IconWrapper>
              </Link>
              <QrCode src={qrCode} />
            </AppcardWrapper>
          </AppCard>

          <Buttons>
            <ShareButton
              onClick={() => {
                handleCopyLink();
              }}
              data-html2canvas-ignore
            >
              {t('result_share_button')}
            </ShareButton>

            <OtherButtonWrapper data-html2canvas-ignore>
              <OtherButton
                onClick={() => {
                  navigate('/');
                }}
              >
                {t('result_try_again_button')}
              </OtherButton>
              <OtherButton
                onClick={async () => {
                  await handleDonwloadImage(mbti);
                }}
              >
                {t('result_download_button')}
              </OtherButton>
            </OtherButtonWrapper>
          </Buttons>
        </Wrapper>
      </Conditional>
    </>
  );
};

export default ResultPage;

const Content = ({ type, plant, desc, imgSrc, mbti }) => {
  const { t } = useTranslation();

  return (
    <ContentWrapper $type={type}>
      <Tag $type={type}>
        {type === 'flower'
          ? t('result_text_plant_identity')
          : t('result_text_lucky_plant')}
      </Tag>
      <ContentTitle $type={type}>{plant}</ContentTitle>
      <DescWrapper>
        <RightWrapper $type={type}>
          <ContentDesc $type={type}>{desc}</ContentDesc>
        </RightWrapper>
        <LeftWrapper>
          <StyledImage src={require(`../assets/${imgSrc}`)} />
        </LeftWrapper>
      </DescWrapper>
    </ContentWrapper>
  );
};

const Popup = ({ message }) => {
  const [hide, setHide] = useState();
  setTimeout(() => {
    setHide(true);
  }, 500);

  return (
    <PopupWrapper $hide={hide}>
      <PopupMessage>{message}</PopupMessage>
    </PopupWrapper>
  );
};

const TransitionWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 auto 0 auto;
  background-image: url(${bgImg});
  background-size: cover;
  height: 100vh;
`;

const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16% 8%;
  gap: 25px;
  align-items: center;
`;

const Caption = styled.div`
  text-align: center;
  font: ${(props) => props.theme.fonts.b2};
  color: ${(props) => props.theme.red1};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.pink1};
`;

const TopWrapper = styled.div`
  background-color: ${(props) => props.theme.pink1};
  margin-top: 5%;
`;

const NameWrapper = styled.div`
  font: ${(props) => props.theme.fonts.h4};
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 16px;
  overflow-wrap: anywhere;
  font-size: 16px;
  justify-content: center;
`;

const Name = styled.div`
  font: ${(props) => props.theme.fonts.h3};
  color: ${(props) => props.theme.dark};
  margin-right: 10px;
  margin-left: 16px;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledText = styled.div`
  font: ${(props) => props.theme.fonts.h4};
  color: ${(props) => props.theme.red1};
  font-size: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background-color: ${(props) =>
    props.$type === 'flower' ? props.theme.pink2 : props.theme.orange1};
  margin-left: 10px;

  @media (max-width: 375px) {
    align-items: center;
    justify-content: center;
  }
`;

const Tag = styled.div`
  display: flex;
  margin-right: auto;
  padding: 5px 12px;
  font: ${(props) => props.theme.fonts.b2};
  color: white;
  background-color: ${(props) =>
    props.$type === 'flower' ? props.theme.red1 : props.theme.orange2};
  border-radius: 20px;
  text-align: right;
  margin-bottom: 16px;

  @media (max-width: 375px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const DescWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentTitle = styled.div`
  font: ${(props) => props.theme.fonts.h5};
  color: ${(props) =>
    props.$type === 'flower' ? props.theme.red1 : props.theme.orange2};
  width: 100%;
  overflow-wrap: anywhere;
  line-height: 20px;
  margin-bottom: 10px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 60%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentDesc = styled.div`
  font: ${(props) => props.theme.fonts.b3};
  color: ${(props) =>
    props.$type === 'flower' ? props.theme.red1 : props.theme.orange2};
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

const StyledImage = styled.img`
  width: 120px;
  height: 120px;
`;

const PlantDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-right: 10px;
`;

const ShareButton = styled.a`
  background-color: ${(props) => props.theme.red1};
  font: ${(props) => props.theme.fonts.b5};
  padding: 10px 20px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 30px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 1px 0.7px 2.7px rgba(0, 0, 0, 0.022),
    2.5px 1.7px 6.9px rgba(0, 0, 0, 0.031),
    5.1px 3.5px 14.2px rgba(0, 0, 0, 0.039),
    10.6px 7.3px 29.2px rgba(0, 0, 0, 0.048), 29px 20px 80px rgba(0, 0, 0, 0.07);
  gap: 10px;

  :hover {
    background-color: white;
    color: ${(props) => props.theme.red1};
  }

  @media (max-width: 375px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const AppCard = styled.div`
  justify-content: end;
  background-color: ${(props) => props.theme.pink1};
  padding: 12px;
  padding-bottom: 16px;
`;

const AppcardWrapper = styled.div`
  background-color: #f6fff9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 1.3px rgb(253 216 226 / 12%),
    0px 0px 4.5px rgb(253 216 226 / 21%), 0px 0px 20px rgb(253 216 226 / 38%);
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

const AppNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppName = styled.div`
  font: ${(props) => props.theme.fonts.b4};
  color: ${(props) => props.theme.green1};
`;

const AppTitle = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.green1};
`;

const QrCode = styled.img`
  width: 52px;
  height: 52px;
`;

const OtherButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 15px;
`;

const OtherButton = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font: ${(props) => props.theme.fonts.b3};
  border: 1px solid ${(props) => props.theme.grey};
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${(props) => props.theme.red1};
    border-color: ${(props) => props.theme.red1};
    color: white;
  }
`;

const Buttons = styled.div`
  padding-right: 8%;
  padding-left: 8%;
  padding-bottom: 10%;
  background-color: ${(props) => props.theme.pink1};
`;

const PopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 8px 20px;
  border-radius: 100px;
  border: none;
`;

const PopupMessage = styled.div`
  font: ${(props) => props.theme.fonts.b5};
  color: ${(props) => props.theme.red1};
`;

const Link = styled.a`
  text-decoration: none;
`;

const AppImage = styled.img`
  width: 150px;
  height: 36px;
  margin-top: 18px;
`;

const PhoneImage = styled.img`
  width: 100px;
  margin-bottom: 16px;
`;

const StyledPinkButton = styled(PinkButton)`
  display: flex;
  align-items: center;
  margin-right: 8%;
  margin-left: 8%;
  justify-content: center;
  background-color: ${(props) =>
    props.$disabled ? props.theme.red1 : props.theme.red3};
`;
