import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../App';
import LanguageSwitcher from '../component/LanguageSwitcher';
import bgImg from '../assets/bg.png';
import PinkButton from '../component/PinkButton';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, handleUpdateUserData } = useContext(UserContext);
  const [name, setName] = useState('');

  const handleNameInputChange = (event) => {
    setName(event.target.value);
  };

  const UpdateUserDataToContext = ({ name }) => {
    const _userData = {
      ...userData,
      name,
    };
    handleUpdateUserData(_userData);
    navigate('/mbti');
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Subtitle>{t('home_subtitle')}</Subtitle>
        <Title>{t('home_title')}</Title>
        <NameWrapper>
          <NameInput
            type="text"
            placeholder={t('home_name_input')}
            value={name}
            onChange={handleNameInputChange}
          />
          <StartButton
            $enabled={name && name.trim() !== ''}
            text={t('home_start')}
            onClick={() => {
              if (name) {
                UpdateUserDataToContext({ name });
              }
            }}
          />
        </NameWrapper>
        <LanguageSwitcher />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  height: 100vh;
  background-image: url(${bgImg});
  background-size: cover;
  background-position: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 30px 0 30px;

  @media (max-width: 280px) {
    margin: 0;
  }
`;

const Title = styled.div`
  font: ${(props) => props.theme.fonts.h2};
  color: ${(props) => props.theme.red1};
  margin-top: 12px;
  max-width: 260px;
  text-align: center;
`;

const Subtitle = styled.div`
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => props.theme.grey};
  border-color: ${(props) => props.theme.grey};
  border: 1px solid;
  border-radius: 20px;
  padding: 1px 10px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 20%;
  width: 85%;

  @media (max-width: 375px) {
    margin-bottom: 10%;
  }
`;

const NameInput = styled.input`
  color: ${(props) => props.theme.dark};
  font: ${(props) => props.theme.fonts.b1};
  padding: 13px 24px;
  border-radius: 16px;
  border: 1px solid;
  border-color: ${(props) => props.theme.grey};
  ::placeholder {
    color: ${(props) => props.theme.grey};
    font: ${(props) => props.theme.fonts.b1};
  }
  &:focus {
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.theme.red1};
  }
`;

const StartButton = styled(PinkButton)`
  margin-top: 17px;
  background-color: ${(props) =>
    props.$enabled ? props.theme.red1 : props.theme.red3};
`;
