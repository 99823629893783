import React, { useContext, useEffect, useState } from 'react';
import { changeLanguage } from 'i18next';
import { UserContext } from '../App';
import styled from 'styled-components';

const LanguageSwitcher = () => {
  const { userData, handleUpdateUserData } = useContext(UserContext);
  const langArr = [
    { key: 'en', name: 'English', emoji: '🟡' },
    { key: 'zh-TW', name: '繁體中文', emoji: '🟠' },
    { key: 'zh-CN', name: '簡體中文', emoji: '🟤' },
    { key: 'ko', name: '한국인', emoji: '🔵' },
    { key: 'pt', name: 'Português', emoji: '🟢' },
  ];
  const [activeLang, setActiveLang] = useState();

  const handleLanguageUpdate = (languageCode) => {
    changeLanguage(languageCode);
    setActiveLang(languageCode);
    handleUpdateUserData({
      ...userData,
      lang: languageCode,
    });
    localStorage.setItem('language', languageCode);
  };

  useEffect(() => {
    const languageCode = localStorage.getItem('language');
    const browserLanguageCode = localStorage.getItem('i18nextLng');

    if (languageCode === 'null') {
      setActiveLang(browserLanguageCode);
      handleLanguageUpdate(browserLanguageCode);
      localStorage.setItem('language', browserLanguageCode);
    } else {
      setActiveLang(languageCode);
      handleLanguageUpdate(languageCode);
    }
  }, []);

  return (
    <Wrapper>
      {langArr.map((lang, idx) => (
        <ButtonWrapper
          key={lang.key}
          $isActive={activeLang === lang.key}
          onClick={() => handleLanguageUpdate(lang.key)}
        >
          {lang.emoji} {lang.name}
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

export default LanguageSwitcher;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-top: 20px;
  background-color: ${(props) =>
    props.$isActive ? props.theme.red1 : 'white'};
  padding: 6px 18px;
  border-radius: 100px;
  border: 1px solid;
  border-color: ${(props) =>
    props.$isActive ? props.theme.red1 : props.theme.grey};
  font: ${(props) => props.theme.fonts.b1};
  color: ${(props) => (props.$isActive ? 'white' : props.theme.dark1)};
  cursor: pointer;
`;
