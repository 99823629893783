import { useCallback } from 'react';
import html2canvas from 'html2canvas';

const useDownloadAsImage = (ref, { parent, ...options } = {}) => {
  const handleDonwloadImage = useCallback(async () => {
    const element = parent ? ref.current.parentElement : ref.current;
    const canvas = await html2canvas(element, options);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `${options.mbti}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  }, [ref, options, parent]);

  return { handleDonwloadImage };
};

export default useDownloadAsImage;

