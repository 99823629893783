import React, { createContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

import Home from './page/Home';
import MBTIForm from './page/MBTIForm';
import LuckForm from './page/LuckForm';
import Layout from './page/Layout';
import Result from './page/Result';

export const UserContext = createContext();

function App() {
  const defaultData = {
    name: '',
    mbti: '',
    flower: '',
    luckType: '',
    suggestPlant: '',
  };
  const [userData, setUserData] = useState(defaultData);
  const handleUpdateUserData = (updatedUserData) => {
    setUserData(updatedUserData);
  };

  return (
    <UserContext.Provider value={{ userData, handleUpdateUserData }}>
      <ThemeProvider theme={theme.default}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="mbti" element={<MBTIForm />} />
            <Route path="luck" element={<LuckForm />} />
            <Route path="result" element={<Result />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
